import React from "react";
import "./RestaurantMenu.css";
// import { Tabs, Tab } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
import SimpleTabs from "../components/Tabs";
import shape from "../shape3.png";

// import KhanehAdinehLogo from "../KhanehAdinehLogo.png";
// import croissant from "../pics/image8.webp";
// import crepe from "../pics/image5.webp";
// import EnglishBF from "../pics/image6.webp";
// import TurkishBF from "../pics/image2.2.webp";
// import PersianBF from "../pics/image4.webp";
// import SpecialOmelette from "../pics/image11.webp";
// import SpinachOmelette from "../pics/image3.webp";
// import Pancake from "../pics/image1.webp";
// import McMuffin from "../pics/image12.3.webp";
// import EspressoDouble from "../pics/image13.webp";
// import KhanehAdinehLogo from "../KhanehAdinehLogo.png";
import KhanehAdinehLogo from "../KhanehAdinehLogo.webp";

import { Outlet, Link } from "react-router-dom";

import arrow from "../arrow.svg";

import res1 from "../pics/res1.webp";
import res2 from "../pics/res2.webp";
import res3 from "../pics/res3.webp";
import res4 from "../pics/res4.webp";
import res5 from "../pics/res5.webp";
import res6 from "../pics/res6.webp";
import res7 from "../pics/res7.webp";
import res8 from "../pics/res8.webp";
import res9 from "../pics/res9.webp";
import res10 from "../pics/res10.webp";
import res11 from "../pics/res11.webp";
import res12 from "../pics/res12.webp";
import res13 from "../pics/res13.webp";
import res14 from "../pics/res14.webp";
import res15 from "../pics/res15.webp";
import res16 from "../pics/res16.webp";
import res17 from "../pics/res17.webp";
import res18 from "../pics/res18.webp";
import res19 from "../pics/res19.webp";
import res20 from "../pics/res20.webp";
import res21 from "../pics/res21.webp";
import res22 from "../pics/res22.webp";
import res23 from "../pics/res23.webp";
import res24 from "../pics/res24.webp";

function RestaurantMenu() {
  // const sections = ["صبحانه", "سالاد", "پیش غذا", "عذای اصلی"];

  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="menu">
      <div className="goUp" onClick={goUp}>
        <img src={arrow} />
      </div>
      <div className="header">
        <div className="backBtnContainer">
          <div className="backBtn">
            <Link to={"/"}>برگشت</Link>
          </div>
        </div>
        <div className="menuLogo">
          <img src={KhanehAdinehLogo} />
        </div>
        <div className="empty"></div>
      </div>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
          {sections.map((item) => (
            <Tab key={item}>{item}</Tab>
          ))}
        </Tabs> */}
      <SimpleTabs restaurant={true} />
      {/* <div className="section">غذای اصلی</div>
        <div className="section">پیش غذا</div>
        <div className="section">صبحانه</div>
        <div className="section">پیش غذای سرد</div>
        <div className="section">سالاد</div> */}

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section3">
            غذای اصلی
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شیشلیک</div>
            <div className="itemTitleEn">
              Shashlik
              <div className="itemLine"></div>
            </div>

            <div className="price">1050 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">گردن</div>
            <div className="itemTitleEn">
              Lamb neck stew
              <div className="itemLine"></div>
            </div>

            <div className="price">850 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res3} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کباب لقمه زعفرانی</div>
            <div className="itemTitleEn">
              Loghmeh kebab <div className="itemLine"></div>
            </div>

            <div className="price">570 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res1} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">فیله مرغ ترش</div>
            <div className="itemTitleEn">
              Kabab torsh<div className="itemLine"></div>
            </div>

            <div className="price">720 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res2} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">جوجه کباب بی استخوان</div>
            <div className="itemTitleEn">
              Jujeh kebab
              <div className="itemLine"></div>
            </div>

            <div className="price">490 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res4} />
        </div>
        {/* <div className="items">
          <div className="description">
            <div className="itemTitleFa">جوجه کباب با استخوان</div>
            <div className="itemTitleEn">
              Bone-In jujeh kebab <div className="itemLine"></div>
            </div>
            <div className="price">570 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res10} />
        </div> */}
        {/* <div className="items">
          <div className="description">
            <div className="itemTitleFa">خورشت فسنجان</div>
            <div className="itemTitleEn">
              Fesenjān
              <div className="itemLine"></div>
            </div>

            <div className="price">440 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res12} />
        </div> */}
        {/* <div className="items">
          <div className="description">
            <div className="itemTitleFa">کباب سبز</div>
            <div className="itemTitleEn">
              Green kebab
              <div className="itemLine"></div>
            </div>

            <div className="price">570 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res11} />
        </div> */}
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">قیمه نثار قزوین</div>
            <div className="itemTitleEn">
              Gheymeh nesaar<div className="itemLine"></div>
            </div>

            <div className="price">630 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چلو ماهی قزل آلا</div>
            <div className="itemTitleEn">
              Trout fish<div className="itemLine"></div>
            </div>

            <div className="price">620 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res6} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چلو کره</div>
            <div className="itemTitleEn">
              Rice<div className="itemLine"></div>
            </div>

            <div className="price">110 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res13} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="پیش غذا"> */}
          <div className="itemsTitle" id="section2">
            پیش غذا
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سوپ جو</div>
            <div className="itemTitleEn">
              Barley soup<div className="itemLine"></div>
            </div>

            <div className="price">120 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کشک بادمجان</div>
            <div className="itemTitleEn">
              Kashk-e bademjan<div className="itemLine"></div>
            </div>

            <div className="price">195 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res5} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">حمص</div>
            <div className="itemTitleEn">
              Hommus{" "}
              {/* <div className="ingredients">
                (۷۰۰ گرم ماهی کبابی شده، به همراه دورچین سبزیجات و سس مخصوص)
              </div> */}
              <div className="itemLine"></div>
            </div>

            <div className="price">295 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res14} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">دلمه</div>
            <div className="itemTitleEn">
              Dolma
              {/* <div className="ingredients">
                (۷۰۰ گرم ماهی کبابی شده، به همراه دورچین سبزیجات و سس مخصوص)
              </div> */}
              <div className="itemLine"></div>
            </div>

            <div className="price">290 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سالاد سزار</div>
            <div className="itemTitleEn">
              Caesar salad
              <div className="itemLine"></div>
            </div>

            <div className="price">395 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res8} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سالاد فصل</div>
            <div className="itemTitleEn">
              Seasonal salad
              <div className="itemLine"></div>
            </div>

            <div className="price">110 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={res9} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سالاد شیرازی</div>
            <div className="itemTitleEn">
              Shirazi salad
              <div className="itemLine"></div>
            </div>

            <div className="price">110 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">بورانی اسفناج</div>
            <div className="itemTitleEn">
              Spinach borani<div className="itemLine"></div>
            </div>

            <div className="price">100 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">بورانی بادمجان کبابی</div>
            <div className="itemTitleEn">
              Borani bademjan<div className="itemLine"></div>
            </div>

            <div className="price">100 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">زیتون سبز ساده</div>
            <div className="itemTitleEn">
              Olive<div className="itemLine"></div>
            </div>

            <div className="price">110 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">زیتون پرورده</div>
            <div className="itemTitleEn">
              Zeytoon parvardeh<div className="itemLine"></div>
            </div>

            <div className="price">130 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">ماست و خیار</div>
            <div className="itemTitleEn">
              Mast-o-khiar<div className="itemLine"></div>
            </div>

            <div className="price">100 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سیر ترشی</div>
            <div className="itemTitleEn">
              Persian Pickled Garlic<div className="itemLine"></div>
            </div>

            <div className="price">80 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section1">
            صبحانه
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">صبحانه ایرانی</div>
            <div className="itemTitleEn">
              Persian breakfast<div className="itemLine"></div>
            </div>

            <div className="price">480 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={res20} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">صبحانه انگلیسی</div>
            <div className="itemTitleEn">
              English breakfast<div className="itemLine"></div>
            </div>

            <div className="price">760 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={res21} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">صبحانه ترکیه‌ای</div>
            <div className="itemTitleEn">
              Turkish breakfast<div className="itemLine"></div>
            </div>

            <div className="price">920 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={res19} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">املت اسفناج و تخم‌مرغ</div>
            <div className="itemTitleEn">
              Spinach & eggs omelette<div className="itemLine"></div>
            </div>

            <div className="price">450 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "cover" }} src={res18} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">املت مخصوص</div>
            <div className="itemTitleEn">
              Special omelette<div className="itemLine"></div>
            </div>

            <div className="price">390 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpecialOmelette} /> */}
          <img style={{ objectFit: "cover" }} src={res23} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">املت سنتی</div>
            <div className="itemTitleEn">
              Classic omelette<div className="itemLine"></div>
            </div>

            <div className="price">290 T</div>
          </div>
          {/* <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سوسیس تخم مرغ</div>
            <div className="itemTitleEn">
              Sausage and egg breakfast<div className="itemLine"></div>
            </div>

            <div className="price">320 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">نیمرو</div>
            <div className="itemTitleEn">
              Fried egg<div className="itemLine"></div>
            </div>

            <div className="price">110 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={crepe} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">بورک</div>
            <div className="itemTitleEn">
              Börek<div className="itemLine"></div>
            </div>

            <div className="price">295 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={crepe} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section0">
            نوشیدنی
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">دوغ محلی</div>
            <div className="itemTitleEn">
              Doogh<div className="itemLine"></div>
            </div>

            <div className="price">40 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کوکاکولا</div>
            <div className="itemTitleEn">
              Coca-Cola<div className="itemLine"></div>
            </div>

            <div className="price">25 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کوکاکولا زیرو</div>
            <div className="itemTitleEn">
              Coca-Cola Zero<div className="itemLine"></div>
            </div>

            <div className="price">25 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">دلستر</div>
            <div className="itemTitleEn">
              Non-Alcoholic beer<div className="itemLine"></div>
            </div>

            <div className="price">30 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">اسپرایت</div>
            <div className="itemTitleEn">
              Sprite<div className="itemLine"></div>
            </div>

            <div className="price">25 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">فانتا</div>
            <div className="itemTitleEn">
              Fanta<div className="itemLine"></div>
            </div>

            <div className="price">25 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آب معدنی</div>
            <div className="itemTitleEn">
              Water<div className="itemLine"></div>
            </div>

            <div className="price">10 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="menuWatermark">
        - Developed by <a href="https://amirb.ir">Amirb.ir</a> -
      </div>
      {/* <div className="bottomSpace"></div> */}
    </div>
  );
}

export default RestaurantMenu;
