// import "./App.css";
import pattern from "./a4.webp";
// import KhanehAdinehLogo from "./KhanehAdinehLogo.png";
import KhanehAdinehLogo from "./KhanehAdinehLogo.webp";
import Button2 from "./Rbutton.webp";
import Button3 from "./Cbutton.webp";
import { useState, useEffect } from "react";
// import RestaurantMenu from "./components/RestaurantMenu";
// import CafeMenu2 from "./components/CafeMenu2.js";
import Loading from "./components/Loading.js";
import { Outlet, Link } from "react-router-dom";

function App() {
  const [restaurant, setRestaurant] = useState(false);
  const [cafe, setCafe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);

  const [showWM, setShowWM] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowWM(true);
    }, 1000);
  }, []);

  const load = () => {
    setLoading(false);
    console.log("pattern loaded.");
  };

  const loadLogo = () => {
    setLoadingLogo(false);
    console.log("logo loaded.");
  };

  return (
    <div className="mainContainer">
      <div className="pattern">
        <div className="watermark">
          - Developed by <a href="https://amirb.ir">Amirb.ir</a> -
        </div>
        <img className="logo" src={KhanehAdinehLogo} onLoad={loadLogo} />
        <img className="fwPattern" src={pattern} onLoad={load} />
        {loading || loadingLogo ? (
          <Loading />
        ) : (
          <div className="button">
            <Link to={"/restaurant"}>
              <img src={Button2} />
            </Link>
            <Link to={"/cafe"}>
              <img src={Button3} />
            </Link>
          </div>
        )}
      </div>
      <div className="bg">
        <div className="body"></div>
      </div>
    </div>
  );
}

export default App;
