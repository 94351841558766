import React from "react";
import "./RestaurantMenu.css";
// import { Tabs, Tab } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
import SimpleTabs from "../components/Tabs";
import shape from "../shape3.png";

// import KhanehAdinehLogo from "../KhanehAdinehLogo.png";
// import croissant from "../pics/image8.webp";
// import crepe from "../pics/image5.webp";
// import EnglishBF from "../pics/image6.webp";
// import TurkishBF from "../pics/image2.2.webp";
// import PersianBF from "../pics/image4.webp";
// import SpecialOmelette from "../pics/image11.webp";
// import SpinachOmelette from "../pics/image3.webp";
// import Pancake from "../pics/image1.webp";
// import McMuffin from "../pics/image12.3.webp";
import img1 from "../pics/image1.webp";
import img2 from "../pics/image2.webp";
import img3 from "../pics/image3.webp";
import img4 from "../pics/image4.webp";
import img5 from "../pics/image5.webp";
import img6 from "../pics/image6.webp";
import img7 from "../pics/image7.webp";
import img8 from "../pics/image8.webp";
import img9 from "../pics/image9.webp";
import img10 from "../pics/image10.webp";
import img11 from "../pics/image11.webp";
import img12 from "../pics/image12.webp";
import img13 from "../pics/image13.webp";
import img14 from "../pics/image14.webp";
import img15 from "../pics/image15.webp";
import img16 from "../pics/image16.webp";
import img17 from "../pics/image17.webp";
import img18 from "../pics/image18.webp";
import img19 from "../pics/image19.webp";
import img20 from "../pics/image20.webp";
import img21 from "../pics/image21.webp";
import img22 from "../pics/image22.webp";
import img23 from "../pics/image23.webp";
import img24 from "../pics/image24.webp";
import img25 from "../pics/image25.webp";
import img26 from "../pics/image26.webp";
import img27 from "../pics/image27.webp";
import img28 from "../pics/image28.webp";
import img29 from "../pics/image29.webp";
import img30 from "../pics/image30.webp";
import img31 from "../pics/image31.webp";
import img32 from "../pics/image32.webp";
import img33 from "../pics/image33.webp";
import img34 from "../pics/image34.webp";
import img35 from "../pics/image35.webp";
import img36 from "../pics/image36.webp";
import img37 from "../pics/image37.webp";
import img38 from "../pics/image38.webp";
import img39 from "../pics/image39.webp";
import img40 from "../pics/image40.webp";
import img41 from "../pics/image41.webp";
import img42 from "../pics/image42.webp";
import img43 from "../pics/image43.webp";
// import img44 from "../pics/image44.webp"; // Cookie

import arrow from "../arrow.svg";
// import KhanehAdinehLogo from "../KhanehAdinehLogo.png";
import KhanehAdinehLogo from "../KhanehAdinehLogo.webp";

import { Outlet, Link } from "react-router-dom";

function CafeMenu2() {
  //   const sections = ["سالاد", "پیش غذا", "صبحانه", "غذای فرنگی", "غذای ایرانی"];

  // const sections = [
  //   "کافی",
  //   "شیک",
  //   "کوکتل",
  //   "آیس کافی",
  //   "اسموتی",
  //   "دمنوش و چای",
  //   "نوشیدنی های گرم",
  //   "کافی بار",
  // ];

  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="menu">
      <div className="goUp" onClick={goUp}>
        <img src={arrow} />
      </div>
      <div className="header">
        <div className="backBtnContainer">
          <div className="backBtn">
            <Link to={"/"}>برگشت</Link>
          </div>
        </div>
        <div className="menuLogo">
          <img src={KhanehAdinehLogo} />
        </div>
        <div className="empty"></div>
      </div>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
          {sections.map((item) => (
            <Tab key={item}>{item}</Tab>
          ))}
        </Tabs> */}
      <SimpleTabs cafe={true} />
      {/* <div className="section">غذای اصلی</div>
        <div className="section">پیش غذا</div>
        <div className="section">صبحانه</div>
        <div className="section">پیش غذای سرد</div>
        <div className="section">سالاد</div> */}

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section10">
            کافی
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">اسپرسو دبل</div>
            <div className="itemTitleEn">
              Double Espresso
              <div className="itemLine"></div>
            </div>

            <div className="price">115 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img7} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">اسپرسو ماکیاتو</div>
            <div className="itemTitleEn">
              Caffè macchiato <div className="itemLine"></div>
            </div>

            <div className="price">120 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img6} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">لاته</div>
            <div className="itemTitleEn">
              Latte<div className="itemLine"></div>
            </div>

            <div className="price">150 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img1} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">لاته با شیر بادام</div>
            <div className="itemTitleEn">
              Almond milk latte<div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img1} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">اسپشیال لاته</div>
            <div className="itemTitleEn">
              Special latte
              <div className="itemLine"></div>
            </div>

            <div className="price">175 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img3} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کاپوچینو</div>
            <div className="itemTitleEn">
              Cappuccino <div className="itemLine"></div>
            </div>
            <div className="price">160 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آمریکانو</div>
            <div className="itemTitleEn">
              Americano
              <div className="itemLine"></div>
            </div>

            <div className="price">130 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img8} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">موکا</div>
            <div className="itemTitleEn">
              Caffè mocha
              <div className="itemLine"></div>
            </div>

            <div className="price">175 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">ماکیاتو</div>
            <div className="itemTitleEn">
              Caffè macchiato
              <div className="ingredients">
                (فندق، کارامل، وانیل، کوکونات، آیریش)
              </div>
              <div className="itemLine"></div>
            </div>
            <div className="price">170 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img2} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کورتادو</div>
            <div className="itemTitleEn">
              Cortado<div className="itemLine"></div>
            </div>

            <div className="price">135 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img4} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">قهوه ترک</div>
            <div className="itemTitleEn">
              Turkish coffee<div className="itemLine"></div>
            </div>

            <div className="price">125 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img5} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section9">
            آیس کافی
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آیس آمریکانو</div>
            <div className="itemTitleEn">
              Iced americano<div className="itemLine"></div>
            </div>

            <div className="price">140 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img24} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آیس لاته</div>
            <div className="itemTitleEn">
              Iced latte<div className="itemLine"></div>
            </div>

            <div className="price">160 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img26} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آفوگاتو</div>
            <div className="itemTitleEn">
              Affogato
              <div className="itemLine"></div>
            </div>

            <div className="price">170 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img9} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آیس ماکیاتو</div>
            <div className="itemTitleEn">
              Iced macchiato
              <div className="ingredients">
                (فندق، کارامل، وانیل، کوکونات، آیریش)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">175 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آیس موکا</div>
            <div className="itemTitleEn">
              Iced mocha
              <div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section8">
            نوشیدنی گرم
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">هات چاکلت</div>
            <div className="itemTitleEn">
              Hot chocolate<div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">وایت چاکلت</div>
            <div className="itemTitleEn">
              White chocolate<div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img20} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای ماسالا</div>
            <div className="itemTitleEn">
              Masala chai<div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img19} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای کرک</div>
            <div className="itemTitleEn">
              Karak tea<div className="itemLine"></div>
            </div>

            <div className="price">195 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img17} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آذرنوش</div>
            <div className="itemTitleEn">
              AZAR Noosh
              <div className="ingredients">(کوکونات، میت، گلاب)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">195 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img21} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">ثعلب</div>
            <div className="itemTitleEn">
              Sahlab<div className="itemLine"></div>
            </div>

            <div className="price">180 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section7">
            دمنوش
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کوئین بری</div>
            <div className="itemTitleEn">
              Queen berry
              <div className="ingredients">
                (چای ترش، زرشک، کرنبری، توت فرنگی)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img11} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">لمون گرس</div>
            <div className="itemTitleEn">
              Lemongrass
              <div className="ingredients">(لمون گرس، نعنا، زنجبیل)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img10} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">اپل پارادایس</div>
            <div className="itemTitleEn">
              Apple paradise
              <div className="ingredients">(سیب، دارچین، به)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img13} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">گلدن وانیلا</div>
            <div className="itemTitleEn">
              Golden vanilla
              <div className="ingredients">(وانیل، میخک، به)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "cover" }} src={img16} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">رویال جاسمین</div>
            <div className="itemTitleEn">
              Royal Jasmine
              <div className="ingredients">(گل یاس، چای سبز)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpecialOmelette} /> */}
          <img style={{ objectFit: "cover" }} src={img12} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">گل گاو زبان</div>
            <div className="itemTitleEn">
              Echium amoenum<div className="itemLine"></div>
            </div>

            <div className="price">170 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img15} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای سیاه لیوانی</div>
            <div className="itemTitleEn">
              Black tea<div className="itemLine"></div>
            </div>

            <div className="price">99 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img22} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای سیاه فرنچ پرس</div>
            <div className="itemTitleEn">
              Black tea<div className="ingredients">(دو نفره)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">179 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={crepe} /> */}
          <img style={{ objectFit: "cover" }} src={img25} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای مراکشی</div>
            <div className="itemTitleEn">
              Moroccan tea<div className="itemLine"></div>
            </div>

            <div className="price">170 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={Pancake} /> */}
          <img style={{ objectFit: "cover" }} src={img14} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چای کمر باریک</div>
            <div className="itemTitleEn">
              Tea<div className="itemLine"></div>
            </div>

            <div className="price">55 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={Pancake} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section6">
            شرابه ها
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">جانان</div>
            <div className="itemTitleEn">
              Janan
              <div className="ingredients">
                (لوندر، بهار نارنج، آلورا، گل گاو زبان)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">255 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img33} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">ایران ناز</div>
            <div className="itemTitleEn">
              Iran Naaz
              <div className="ingredients">(زعفران، بیدمشک، کاسنی پرتقال)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">265 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img34} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">نازدار</div>
            <div className="itemTitleEn">
              Naazdar
              <div className="ingredients">
                (دارچین، هل، بهار نارنج، سرد دم کوئین بری)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">255 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img35} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section5">
            اسموتی
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">نازک</div>
            <div className="itemTitleEn">
              Naazak
              <div className="ingredients">
                (کوکونات، پشن‌فروت، انبه، آناناس، موز، پرتقال)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">285 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img37} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">جهان ناز</div>
            <div className="itemTitleEn">
              Jahan Naaz
              <div className="ingredients">
                (لواشک، شاهتوت، تمر هندی، آلبالو، پرتقال)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">295 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img39} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">نازتاب</div>
            <div className="itemTitleEn">
              Naaztab
              <div className="ingredients">
                (شیر، کوکونات، ماست میوه، آناناس، آلورا)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">280 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img38} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section4">
            میلک شیک
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شیک پیناتوس</div>
            <div className="itemTitleEn">
              Peanutus milkshake<div className="itemLine"></div>
            </div>

            <div className="price">320 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img41} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شیک فروتی</div>
            <div className="itemTitleEn">
              Fruits milkshake<div className="itemLine"></div>
            </div>

            <div className="price">320 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شکلات فندق</div>
            <div className="itemTitleEn">
              Chocolate & hazelnut milkshake<div className="itemLine"></div>
            </div>

            <div className="price">320 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img40} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">بستنی سنتی</div>
            <div className="itemTitleEn">
              Traditional ice cream
              <div className="ingredients">(1 اسکوپ)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">45 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">بستنی وانیلی</div>
            <div className="itemTitleEn">
              Vanilla ice cream
              <div className="ingredients">(1 اسکوپ)</div>
              <div className="itemLine"></div>
            </div>

            <div className="price">30 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section3">
            ماکتل
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">لیموناد</div>
            <div className="itemTitleEn">
              Lemonade<div className="itemLine"></div>
            </div>

            <div className="price">150 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img31} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">موهیتو کلاسیک</div>
            <div className="itemTitleEn">
              Mojito<div className="itemLine"></div>
            </div>

            <div className="price">150 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img30} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">ماه تابان</div>
            <div className="itemTitleEn">
              Mah Taban
              <div className="ingredients">
                (کوکونات، وانیل، گرانادین، آناناس)
              </div>
              <div className="itemLine"></div>
            </div>

            <div className="price">210 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img32} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">دلدار</div>
            <div className="itemTitleEn">
              Deldar<div className="itemLine"></div>
            </div>

            <div className="price">210 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "cover" }} src={img36} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          {/* <div className="itemsTitle" id="صبحانه"> */}
          <div className="itemsTitle" id="section2">
            نوشیدنی فصل
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آب پرتقال</div>
            <div className="itemTitleEn">
              Oranje juice<div className="itemLine"></div>
            </div>

            <div className="price">200 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={PersianBF} /> */}
          <img style={{ objectFit: "cover" }} src={img27} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آب هندوانه</div>
            <div className="itemTitleEn">
              Watermelon juice<div className="itemLine"></div>
            </div>

            <div className="price">195 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={EnglishBF} /> */}
          <img style={{ objectFit: "cover" }} src={img29} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">آب طالبی</div>
            <div className="itemTitleEn">
              Melon juice<div className="itemLine"></div>
            </div>

            <div className="price">200 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={img28} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section1">
            دسر
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">چیز کیک نیویورکی</div>
            <div className="itemTitleEn">
              New York cheesecake<div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img43} />
        </div>
        {/* <div className="items">
          <div className="description">
            <div className="itemTitleFa">تارت توت فرنگی</div>
            <div className="itemTitleEn">
              Strawberry tart<div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          <img style={{ objectFit: "cover" }} src={img42} />
        </div> */}

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">باقلوا</div>
            <div className="itemTitleEn">
              Baklava<div className="itemLine"></div>
            </div>

            <div className="price">190 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">هاویج بستنی</div>
            <div className="itemTitleEn">
              Havij bastani<div className="itemLine"></div>
            </div>

            <div className="price">260 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">دسر مخصوص خانه آدینه</div>
            <div className="itemTitleEn">
              Speical desert<div className="itemLine"></div>
            </div>

            <div className="price">170 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">نوشیزبار با بستنی</div>
            <div className="itemTitleEn">
              Nooshizbar<div className="itemLine"></div>
            </div>

            <div className="price">170 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">تریلیچه پسته</div>
            <div className="itemTitleEn">
              Tres leches with pistachio<div className="itemLine"></div>
            </div>

            <div className="price">250 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کازان دیبی</div>
            <div className="itemTitleEn">
              Kazandibi<div className="itemLine"></div>
            </div>

            <div className="price">230 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={SpinachOmelette} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        {/* <div className="items">
          <div className="description">
            <div className="itemTitleFa">چیز کیک لوتوس</div>
            <div className="itemTitleEn">
              Lotus biscoff cheesecake<div className="itemLine"></div>
            </div>
            <div className="price">190 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
        <div className="items">
          <div className="description">
            <div className="itemTitleFa">کیک نوتلا</div>
            <div className="itemTitleEn">
              Nutella cake<div className="itemLine"></div>
            </div>
            <div className="price">170 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div> */}
      </div>

      <div className="itemsContainer">
        <div className="titleContainer">
          <div className="line"></div>
          <div className="itemsTitle" id="section0">
            تاپینگ
          </div>
          <div className="icon">
            <img src={shape} />
          </div>
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شات سیروپ</div>
            <div className="itemTitleEn">
              Syrup shot<div className="itemLine"></div>
            </div>
            <div className="price">15 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شات شیر</div>
            <div className="itemTitleEn">
              Milk shot<div className="itemLine"></div>
            </div>

            <div className="price">15 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">شات عسل</div>
            <div className="itemTitleEn">
              Honey shot<div className="itemLine"></div>
            </div>

            <div className="price">15 T</div>
          </div>
          {/* <img style={{ objectFit: "cover" }} src={TurkishBF} /> */}
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">سرویس اضافه چای</div>
            <div className="itemTitleEn">
              Extra tea treats<div className="itemLine"></div>
            </div>

            <div className="price">30 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>

        <div className="items">
          <div className="description">
            <div className="itemTitleFa">لیمو</div>
            <div className="itemTitleEn">
              Lime<div className="itemLine"></div>
            </div>

            <div className="price">10 T</div>
          </div>
          <img style={{ objectFit: "contain" }} src={KhanehAdinehLogo} />
        </div>
      </div>

      <div className="menuWatermark">
        - Developed by <a href="https://amirb.ir">Amirb.ir</a> -
      </div>
      {/* <div className="bottomSpace"></div> */}
    </div>
  );
}

export default CafeMenu2;
