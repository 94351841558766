import React from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "./Tabs.css";

const SimpleTabs = ({ restaurant, cafe }) => {
  let sections;
  if (restaurant) {
    sections = ["نوشیدنی", "صبحانه", "پیش غذا", "غذای اصلی"];
  } else if (cafe) {
    sections = [
      "تاپینگ",
      "دسر",
      "نوشیدنی فصل",
      "ماکتل",
      "میلک شیک",
      "اسموتی",
      "شرابه ها",
      "دمنوش",
      "نوشیدنی گرم",
      "آیس کافی",
      "کافی",
    ];
  }

  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = React.useState(sections.length - 1);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    console.log("e", e);
    console.log("index", index);
    setActiveTab(index);
    e.preventDefault();
    console.log("Breakfast", document.querySelector(`#section${index}`));
    // console.log("item", item);
    window.scrollTo({
      top: document.querySelector(`#section${index}`).offsetTop - 35,
      behavior: "smooth",
    });
  };

  return (
    <Tabs activeTab={activeTab} onTabClick={onTabClick} isRTL={true}>
      {/* generating an array to loop through it  */}
      {sections.map((item) => (
        <Tab key={item}>{item}</Tab>
      ))}
    </Tabs>
  );
};

export default SimpleTabs;
